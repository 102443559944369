import React from "react";
import { graphql } from "gatsby";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import { GatsbyImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import ButtonSolid from "../components/Button/ButtonSolid";

import HeroStacked from "../components/Hero/HeroStacked";
import Testimonials from "../components/Repeating/Testimonials";
import WhyUs from "../components/Repeating/WhyUs";
import CallToAction from "../components/Repeating/CTA";

const Page = ({ data }) => {
   return (
      <Layout headerStyle="standard" headerLinkColor="" headerHasBorder={false}>
         <SearchEngineOptimization
            title="Personal Bookkeeping Services San Diego | Smart Accounting"
            description="Smart Accounting Hub provides trustworthy personal bookkeeping services to business owners and individuals in San Diego and beyond. Get a quote today!"
            openGraphImage={data.openGraphImage.publicURL}
            twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
         />

         <HeroStacked
            image={data.hero.childImageSharp.gatsbyImageData}
            backgroundFixed={true}
            imageMaxHeight="max-h-[468px]"
            heading="Personal Bookkeeping Services"
            subtext="Managing your finances can be stressful and overwhelming. We’ll help you get organized so you can thrive personally and professionally."
            textMaxWidth="max-w-4xl"
         />

         <section className="py-20 md:py-32">
            <div className="container">
               <div className="grid grid-cols-1 md:grid-cols-2 gap-y-12 md:gap-x-10 lg:gap-x-20 items-center">
                  <div>
                     <GatsbyImage image={data.intro.childImageSharp.gatsbyImageData} alt="Intro" />
                  </div>
                  <div>
                     <h2>A Personal Bookkeeper You Can Rely On</h2>
                     <p>
                        Smart Accounting Hub provides trustworthy personal bookkeeping services to business owners and individuals in San Diego and
                        beyond. Get the peace of mind you need with our years of experience, time-tested processes, and friendly, transparent service.
                        From reconciling accounts to providing reports, we can handle everything you need.
                     </p>
                     <ButtonSolid modal="modal-contact" text="Get a Quote" />
                  </div>
               </div>
            </div>
         </section>

         <section className="pb-20 md:pb-32">
            <div className="container">
               <header className="mb-12 md:mb-16">
                  <h2>Our Personal Bookkeeping Services</h2>
               </header>
               <div className="grid md:grid-cols-2 gap-y-10 md:gap-x-10 lg:gap-x-20 items-center">
                  <div>
                     <h3 className="text-lg mb-2">Getting Started</h3>
                     <p className="mb-0">
                        We start by reconciling monthly accounts, entering all credit card statements and receipts, and reviewing and tracking all
                        payments.
                     </p>
                  </div>
                  <div>
                     <h3 className="text-lg mb-2">Multimedia Organization</h3>
                     <p className="mb-0">
                        We’ll digitize all paper documents, receipts, and records, and provide a year-end organized paper filing copy for your
                        convenience.
                     </p>
                  </div>
                  <div>
                     <h3 className="text-lg mb-2">Balancing Books</h3>
                     <p className="mb-0">
                        We reconcile all monthly account statements and receipts to keep track of all financials. With our help, your business’s books
                        stay accurate and organized.
                     </p>
                  </div>
                  <div>
                     <h3 className="text-lg mb-2">Year-End Convenience</h3>
                     <p className="mb-0">
                        We provide year-end profit and loss statements, balance sheets, and any other financial reports or records your accountant
                        needs in digital format.
                     </p>
                  </div>
               </div>
            </div>
         </section>

         <WhyUs />

         <Testimonials className="pt-20 md:pt-32 mb-20 md:mb-32" />

         <section className="pb-20 md:pb-32">
            <div className="container">
               <header className="mb-14 md:mb-18">
                  <h2>Related Services</h2>
               </header>
               <div className="grid md:grid-cols-3 md:gap-x-10 gap-y-12">
                  <div className="group relative">
                     <div className="overflow-hidden mb-6">
                        <GatsbyImage
                           image={data.businessBookkeeping.childImageSharp.gatsbyImageData}
                           alt="Business Bookkeeping Services"
                           className="transform scale-100 md:group-hover:scale-110 transition-all duration-500 ease-linear"
                        />
                     </div>
                     <h3 className="text-lg mb-2">Business Bookkeeping</h3>
                     <p className="mb-0">We’ll reconcile your business revenue, expenses, and bank statements each month. </p>
                     <AniLink fade to="/business-bookkeeping-services/">
                        <span className="link-overlay"></span>
                     </AniLink>
                  </div>
                  <div className="group relative">
                     <div className="overflow-hidden mb-6">
                        <GatsbyImage
                           image={data.businessManagement.childImageSharp.gatsbyImageData}
                           alt="Business Management Services"
                           className="transform scale-100 md:group-hover:scale-110 transition-all duration-500 ease-linear"
                        />
                     </div>
                     <h3 className="text-lg mb-2">Business Management</h3>
                     <p className="mb-0">We organize or create new processes to help your business operations be more efficient.</p>
                     <AniLink fade to="/small-business-management-services/">
                        <span className="link-overlay"></span>
                     </AniLink>
                  </div>
                  <div className="group relative">
                     <div className="overflow-hidden mb-6">
                        <GatsbyImage
                           image={data.financialReporting.childImageSharp.gatsbyImageData}
                           alt="Financial Reporting"
                           className="transform scale-100 md:group-hover:scale-110 transition-all duration-500 ease-linear"
                        />
                     </div>
                     <h3 className="text-lg mb-2">Financial Reporting</h3>
                     <p className="mb-0">Because cash flow management is more important than a bank balance or revenue growth.</p>
                     <AniLink fade to="/financial-reporting-services/">
                        <span className="link-overlay"></span>
                     </AniLink>
                  </div>
               </div>
            </div>
         </section>

         <CallToAction subText="Get personal bookkeeping services you can trust. Call us or request a quote today." />
      </Layout>
   );
};

export const query = graphql`
   {
      openGraphImage: file(relativePath: { eq: "open-graph/facebook/Personal Bookeeping.jpg" }) {
         publicURL
      }
      twitterOpenGraphImage: file(relativePath: { eq: "open-graph/twitter/Personal Bookeeping.jpg" }) {
         publicURL
      }
      hero: file(relativePath: { eq: "services/Personal Bookkeeping/1.0 Personal Bookkeeping.jpg" }) {
         childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 100)
         }
      }
      intro: file(relativePath: { eq: "services/Personal Bookkeeping/2.0 Personal booking-intro.jpg" }) {
         childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 100)
         }
      }
      personalBookkeeping: file(relativePath: { eq: "services/Personal Bookkeeping.jpg" }) {
         childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 100)
         }
      }
      businessBookkeeping: file(relativePath: { eq: "services/Business Bookkeeping.jpg" }) {
         childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 100)
         }
      }
      businessManagement: file(relativePath: { eq: "services/Business Management Services.jpg" }) {
         childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 100)
         }
      }
      financialReporting: file(relativePath: { eq: "services/Financial Reporting.jpg" }) {
         childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 100)
         }
      }
   }
`;
export default Page;
